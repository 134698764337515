<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4" lg="4">
        <label class="label-input">{{ $t("e-approve.label_select") }}</label>
        <v-select
          :items="todoStatus"
          outlined
          dense
          item-value="value"
          item-text="name"
          v-model="status"
          @change="searchFilterItem()"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4" lg="4">
        <v-btn
          class="mt-md-7 mr-md-2 btn-create lfont btn"
          color="info"
          @click="onCreate"
        >
          <v-icon left>
            mdi-export
          </v-icon>
          {{ $t("empLeave.btn_create") }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="mb-4">
      <v-col
        cols="12"
        md="3"
        lg="3"
        v-for="(holiday, idx) in listEmployeeHoliday"
        :key="idx"
      >
        <v-card elevation="4" class="pa-3 mb-4">
          <h5 class="lfont">{{ holiday.name }}</h5>
          <span
            class="lfont"
            style="font-size: 80%; color: #676565;"
            v-if="
              holiday.using_holiday.days > 0 ||
                holiday.using_holiday.hours > 0 ||
                holiday.using_holiday.minute > 0
            "
            >Time spent :
            <span v-if="holiday.using_holiday.days > 0"
              >{{ holiday.using_holiday.days }} ມື້
            </span>
            <span v-if="holiday.using_holiday.hours > 0"
              >{{ holiday.using_holiday.hours }} ຊົ່ວໂມງ
            </span>
            <span v-if="holiday.using_holiday.minute > 0"
              >{{ holiday.using_holiday.minute }} ນາທີ</span
            > </span
          ><br />
          <span class="lfont" style="font-size: 80%; color: #676565;"
            >Time remaining :
            <span v-if="holiday.remaining_holiday.days > 0"
              >{{ holiday.remaining_holiday.days }} ມື້
            </span>
            <span v-if="holiday.remaining_holiday.hours > 0"
              >{{ holiday.remaining_holiday.hours }} ຊົ່ວໂມງ
            </span>
            <span v-if="holiday.remaining_holiday.minute > 0"
              >{{ holiday.remaining_holiday.minute }} ນາທີ</span
            >
          </span>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="section-form-content">
          <div class="block-content">
            <table class="table table-view" v-if="listEmployeeLeave.length > 0">
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("empLeave.table.leaveType") }}
                  </th>
                  <th class="text-left">
                    {{ $t("empLeave.table.leaveTaken") }}
                  </th>
                  <th class="text-left">
                    {{ $t("empLeave.table.leaveAvailable") }}
                  </th>
                  <th class="text-left">
                    {{ $t("empLeave.table.backToWork") }}
                  </th>
                  <th class="text-left">
                    {{ $t("empLeave.table.leaveDetail") }}
                  </th>
                  <th class="text-left">
                    {{ $t("empLeave.table.lineManager") }}
                  </th>
                  <th class="text-left">
                    {{ $t("empLeave.table.status") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in listEmployeeLeave" :key="index">
                  <td>{{ item.holiday.name }}</td>
                  <td>
                    <!-- <span
                      v-for="(leave, indexl) in item.leave_time"
                      :key="indexl"
                    >
                      <span style="font-size: 70%;"
                        >{{ leave.date
                        }}<span v-if="leave.type == 'hour'"
                          >({{ leave.start_time }} - {{ leave.end_time }})</span
                        ></span
                      ><br />
                    </span> -->
                    <span v-if="item.summary_leave.day > 0"
                      >{{ item.summary_leave.day }} ມື້
                    </span>
                    <span v-if="item.summary_leave.hour > 0"
                      >{{ item.summary_leave.hour }} ຊົ່ວໂມງ
                    </span>
                    <span v-if="item.summary_leave.minute > 0"
                      >{{ item.summary_leave.minute }} ນາທີ
                    </span>
                  </td>
                  <td>
                    <span v-if="item.holiday.remaining_holiday.days > 0"
                      >{{ item.holiday.remaining_holiday.days }} ມື້
                    </span>
                    <span v-if="item.holiday.remaining_holiday.hours > 0"
                      >{{ item.holiday.remaining_holiday.hours }} ຊົ່ວໂມງ
                    </span>
                    <span v-if="item.holiday.remaining_holiday.minute > 0"
                      >{{ item.holiday.remaining_holiday.minute }} ນາທີ
                    </span>
                  </td>
                  <!-- @click="showLeaveDetail(item.id)" -->
                  <td>{{ item.come_back_date }}</td>
                  <td>
                    <v-btn
                      icon
                      color="indigo"
                      @click="showLeaveDetail(item.id)"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </td>
                  <td>{{ item.approved_by.name }}</td>

                  <td>
                    <span v-if="item.status == 'pending'">
                      <v-chip
                        small
                        style="background-color: rgb(102, 104, 102) !important;color:white !important"
                      >
                        {{ $t("empLeave.status.wait_for_approved") }}
                      </v-chip>
                    </span>
                    <span v-if="item.status == 'approved'">
                      <v-chip
                        small
                        style="background-color: rgb(51, 73, 98) !important;color:white !important"
                      >
                        {{ $t("empLeave.status.lineManagerApproved") }}
                      </v-chip>
                    </span>
                    <span v-if="item.status == 'success'">
                      <v-chip
                        small
                        style="background-color: rgb(37, 184, 37) !important;color:white !important"
                      >
                        {{ $t("empLeave.status.approved") }}
                      </v-chip>
                    </span>
                    <span v-if="item.status == 'rejected'">
                      <v-chip
                        small
                        style="background-color: red !important;color:white !important"
                      >
                        {{ $t("empLeave.status.rejected") }}
                      </v-chip>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <defaultTableNoResult v-else />
            <Loading v-if="isLoading" />
            <ModalEdit>
              <template v-slot="{ close }">
                <ModalShowDetail :LeaveDetail="LeaveDetail" @close="close" />
              </template>
            </ModalEdit>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ModalShowDetail from "@/views/MainEmployees/EmployeeLeave/ModalHrApprove/ModalShowDetail";
import Loading from "@/components/Loading";
import Pagination from "../../../components/Paginate/Pagination";
import defaultTableNoResult from "@/components/defaultTableNoResult";

export default {
  components: {
    ModalShowDetail,
    Loading,
    Pagination,
    defaultTableNoResult,
  },
  data() {
    return {
      status: "all",
      isLoading: false,
      LeaveDetail: {},
      listEmployeeLeave: [],
      listEmployeeHoliday: [],
      todoStatus: [
        {
          id: 0,
          name: this.$t("empLeave.status.all"),
          value: "all",
        },
        {
          id: 1,
          name: this.$t("empLeave.status.wait_for_approved"),
          value: "pending",
        },
        {
          id: 2,
          name: this.$t("empLeave.status.lineManagerApproved"),
          value: "approved",
        },
        {
          id: 3,
          name: this.$t("empLeave.status.approved"),
          value: "success",
        },
        {
          id: 4,
          name: this.$t("empLeave.status.rejected"),
          value: "rejected",
        },
      ],
    };
  },
  methods: {
    onCreate() {
      this.$router
        .push({
          name: "employee_leave.add",
        })
        .catch(() => {});
    },
    searchFilterItem() {
      this.fetchEmployeeLeave();
      // this.pagination.current_page = 1;
    },

    filterDetail(id) {
      return (
        this.listEmployeeLeave.filter((item) => {
          return item.id == id;
        })[0] || {}
      );
    },
    showLeaveDetail(id) {
      this.LeaveDetail = {
        ...this.filterDetail(id),
      };
      this.$store.commit("modalEdit_State", true);
    },

    fetchEmployeeLeave() {
      this.isLoading = true;
      const items = {
        status: this.status,
      };
      this.$axios
        .get(`/employee/list/leaves/${this.status}`)
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.listEmployeeLeave = res.data.data;
            // this.pagination = res.data.data.pagination;
            // if (!this.listEmployeeLeave.length > 0) {
            //   this.pagination.current_page = this.pagination.current_page - 1;
            // }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    fetchEmployeeHoliday() {
      this.isLoading = true;

      this.$axios
        .get(`/employee/list/holidays`)
        .then((res) => {
          if (res.status === 200) {
            // console.log(res);
            this.isLoading = false;
            this.listEmployeeHoliday = res.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchEmployeeLeave();
    this.fetchEmployeeHoliday();
  },
};
</script>

<style lang="scss" scoped>
.custom_table {
  margin-top: 10px;
  border: 1px solid rgb(131, 128, 128);
  width: auto;
  height: auto;
  padding: 5px;
}
</style>
